var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":16}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Crear recurso audio")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Curso"}},[_c('ValidationProvider',{attrs:{"name":"course","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"width-100",attrs:{"size":"mini","filterable":"","placeholder":"título"},model:{value:(_vm.form.course_id),callback:function ($$v) {_vm.$set(_vm.form, "course_id", $$v)},expression:"form.course_id"}},_vm._l((_vm.listItemsCoursesTitle),function(itemCourses){return _c('el-option',{key:itemCourses.value,attrs:{"label":itemCourses.title,"value":itemCourses._id}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Título","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"Titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Descripción"}},[_c('tinyeditor',{attrs:{"api-key":"epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex","init":{
                      height: 250,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('el-form-item',{attrs:{"label":"Duración"}},[_c('ValidationProvider',{attrs:{"name":"duración","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Archivo audio .mp3"}},[_c('ValidationProvider',{attrs:{"name":"audio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-upload',{staticClass:"upload-demo",attrs:{"name":"file","action":"#","http-request":_vm.postAudio,"on-success":_vm.handleAvatarSuccessAudio,"multiple":""}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("Clic para subir archivo")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos .mp3 con un tamaño menor de 10MB")])],1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Orden","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-input-number',{attrs:{"controls-position":"right","min":0},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Estatus"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":invalid,"loading":_vm.loading,"icon":"el-icon-upload2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onStore($event)}}},[_vm._v(" Crear")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }